/*
 * bootstrap variables overrides
 */

/*
 * bootstrap import
 */

@import 'bootstrap/scss/bootstrap';

/*
 * blueprintjs variables import
 */

@import '@blueprintjs/core/lib/scss/variables';
// @import "@blueprintjs/table/lib/css/table";

/*
 *
 */
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

/*
 *
 */
#outerPage {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  background-color: $dark-gray3;

  #page {
    min-width: 400px;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    height: 100%;
    padding: 0 !important;
    background-color: $light-gray5;

    header {
      // background-color: $dark-gray4;

      @include media-breakpoint-up(sm) {
        //
      }

      @include media-breakpoint-up(md) {
        //
      }

      @include media-breakpoint-up(lg) {
        //
      }

      @include media-breakpoint-up(xl) {
        //
      }

      @include media-breakpoint-up(xxl) {
        //
      }
    }

    main#outerApp {
      //
      flex: 1;
      overflow: auto;
      height: 100%;

      &.withColumns {
        display: flex;
        flex-direction: row;
        height: 100%;
        overflow: hidden;

        #navigationSidebar {
          background-color: $white;
          display: none;

          @include media-breakpoint-up(sm) {
            //
          }

          @include media-breakpoint-up(md) {
            //
          }

          @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
            flex: 3;
            overflow: auto;
          }

          @include media-breakpoint-up(xl) {
            flex: 2.5;
          }

          @include media-breakpoint-up(xxl) {
            flex: 2;
          }
        }

        #appContainer {
          display: flex;
          flex-direction: column;
          flex: 10;

          @include media-breakpoint-up(sm) {
            //
          }

          @include media-breakpoint-up(md) {
            //
          }

          @include media-breakpoint-up(lg) {
            //
          }

          @include media-breakpoint-up(xl) {
            //
          }

          @include media-breakpoint-up(xxl) {
            //
          }

          #appTopBar {
            @include media-breakpoint-up(sm) {
              //
            }

            @include media-breakpoint-up(md) {
              //
            }

            @include media-breakpoint-up(lg) {
              //
            }

            @include media-breakpoint-up(xl) {
              //
            }

            @include media-breakpoint-up(xxl) {
              //
            }
          }

          #appContent {
            flex: 1;

            @include media-breakpoint-up(sm) {
              //
            }

            @include media-breakpoint-up(md) {
              //
            }

            @include media-breakpoint-up(lg) {
              //
            }

            @include media-breakpoint-up(xl) {
              //
            }

            @include media-breakpoint-up(xxl) {
              //
            }
          }
        }

        &.withPadding {
          @extend .p-0;
        }
      }

      &.withScrollableContent {
        #appContainer {
          #appContent {
            overflow: auto;
          }
        }
      }

      &.centered {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.withPadding {
        @extend .p-1;
        @extend .p-sm-1;
        @extend .p-lg-4;
        @extend .p-xxl-5 !optional;

        #appContainer {
          #appContent {
            @extend .p-3;
            @extend .p-xxl-4 !optional;
          }
        }
      }
    }

    footer {
      // background-color: $dark-gray4;

      @include media-breakpoint-up(sm) {
        //
      }

      @include media-breakpoint-up(md) {
        //
      }

      @include media-breakpoint-up(lg) {
        //
      }

      @include media-breakpoint-up(xl) {
        //
      }

      @include media-breakpoint-up(xxl) {
        //
      }
    }
  }
}

/*
 *
 */
.console-centered-form {
  width: 98%;
  height: 100%;
  padding-top: 10%;
  max-width: 304px; // from recaptcha width
  @include media-breakpoint-up(sm) {
    //
  }

  @include media-breakpoint-up(md) {
    //
  }

  @include media-breakpoint-up(lg) {
    //
  }

  @include media-breakpoint-up(xl) {
    //
  }

  @include media-breakpoint-up(xxl) {
    //
  }
}
/*
 *
 */
.console-form {
  width: 98%;
  height: 100%;
  max-width: 400px; // from recaptcha width
  @include media-breakpoint-up(sm) {
    //
  }

  @include media-breakpoint-up(md) {
    //
  }

  @include media-breakpoint-up(lg) {
    //
  }

  @include media-breakpoint-up(xl) {
    //
  }

  @include media-breakpoint-up(xxl) {
    //
  }
}

.console-stripe-card-element-container {
  background-color: rgba(16, 22, 26, 0.3);
  padding: 11px;
}

.bp3-overlay {
  z-index: 1000;
}
