:root {
  --first-x: -175%;
  --first-y: -175%;
  --second-x: 75%;
  --second-y: -175%;
  --third-x: 75%;
  --third-y: 75%;
  --fourth-x: -175%;
  --fourth-y: 75%;
  --unit: 325%;
  --size: 24px;
  --point-color: var(--infobiotech);
  --point-shadow: 0 2px 10px rgb(50, 50, 50, 0.4);
}

.ibt-loader.centered {
  padding: calc(var(--size) * 2) 0 calc(var(--size) * 3);
}

.ibt-load-txt {
  padding: calc(var(--size) * 4) 0 0;
  text-align: center;
  margin-top: 8px;
}

.ibt-loader-points {
  position: static;
  justify-content: center;
  align-items: center;
  padding: 0 calc(50% - calc(var(--size) * 3 / 4));
}

.ibt-loader-point {
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 0;
  width: calc(var(--size) / 5);
  height: calc(var(--size) / 5);
  background-color: var(--point-color);
  margin: 18px;
}

/* no animate points */
.ibt-loader-points.no-animate .ibt-loader-point {
  animation: none;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  box-shadow: var(--point-shadow);
}

.ibt-loader-points:not(.no-animate) .ibt-loader-point.first {
  animation: load-first 5s ease-in-out infinite;
}
/* first point no animate */
.ibt-loader-points.no-animate .ibt-loader-point.first {
  transform: translate(var(--first-x), var(--first-y));
}
/*
 * second point
 */
.ibt-loader-points:not(.no-animate) .ibt-loader-point.second {
  animation: load-second 5s ease-in-out infinite;
}
/* no animate */
.ibt-loader-points.no-animate .ibt-loader-point.second {
  transform: translate(var(--second-x), var(--second-y));
}
/*
 * third point
 */
.ibt-loader-points:not(.no-animate) .ibt-loader-point.third {
  animation: load-third 5s ease-in-out infinite;
}
/* no animate */
.ibt-loader-points.no-animate .ibt-loader-point.third {
  transform: translate(var(--third-x), var(--third-y));
}
/*
 * fourth point
 */
.ibt-loader-points:not(.no-animate) .ibt-loader-point.fourth {
  animation: load-fourth 5s ease-in-out infinite;
}
/* no animate */
.ibt-loader-points.no-animate .ibt-loader-point.fourth {
  transform: translate(var(--fourth-x), var(--fourth-y));
}

@keyframes load-first {
  5% {
    border-radius: 50%;
  }
  10% {
    transform: translate(var(--first-x), var(--first-y));
    width: calc(var(--size) * 6 / 5);
    height: calc(var(--size) * 6 / 5);
  }
  13% {
    width: var(--size);
    height: var(--size);
    box-shadow: var(--point-shadow);
  }
  22% {
    transform: translate(var(--first-x), var(--first-y));
    background-color: var(--infobiotech);
  }
  33% {
    transform: translate(calc(var(--first-x) + var(--unit)), var(--first-y));
  }
  33% {
    transform: translate(calc(var(--first-x) + var(--unit)), var(--first-y));
  }
  48% {
    transform: translate(calc(var(--first-x) + var(--unit)), calc(var(--first-y) + var(--unit)));
  }
  48% {
    transform: translate(calc(var(--first-x) + var(--unit)), calc(var(--first-y) + var(--unit)));
  }
  63% {
    transform: translate(var(--first-x), calc(var(--first-y) + var(--unit)));
  }
  63% {
    transform: translate(var(--first-x), calc(var(--first-y) + var(--unit)));
  }
  80% {
    transform: translate(var(--first-x), var(--first-y));
    background-color: var(--infobiotech);
  }
  90% {
    transform: translate(var(--first-x), var(--first-y));
    width: var(--size);
    height: var(--size);
    box-shadow: var(--point-shadow);
  }
  95% {
    border-radius: 50%;
  }
}

@keyframes load-second {
  5% {
    border-radius: 50%;
  }
  10% {
    transform: translate(var(--second-x), var(--second-y));
    width: calc(var(--size) * 6 / 5);
    height: calc(var(--size) * 6 / 5);
  }
  13% {
    width: var(--size);
    height: var(--size);
    box-shadow: var(--point-shadow);
  }
  22% {
    transform: translate(var(--second-x), var(--second-y));
    background-color: var(--infobiotech);
  }
  33% {
    transform: translate(var(--second-x), calc(var(--second-y) + var(--unit)));
  }
  33% {
    transform: translate(var(--second-x), calc(var(--second-y) + var(--unit)));
  }
  48% {
    transform: translate(calc(var(--second-x) - var(--unit)), calc(var(--second-y) + var(--unit)));
  }
  48% {
    transform: translate(calc(var(--second-x) - var(--unit)), calc(var(--second-y) + var(--unit)));
  }
  63% {
    transform: translate(calc(var(--second-x) - var(--unit)), var(--second-y));
  }
  63% {
    transform: translate(calc(var(--second-x) - var(--unit)), var(--second-y));
  }
  80% {
    transform: translate(var(--second-x), var(--second-y));
    background-color: var(--infobiotech);
  }
  90% {
    transform: translate(var(--second-x), var(--second-y));
    width: var(--size);
    height: var(--size);
    box-shadow: var(--point-shadow);
  }
  95% {
    border-radius: 50%;
  }
}

@keyframes load-third {
  5% {
    border-radius: 50%;
  }
  10% {
    transform: translate(var(--third-x), var(--third-y));
    width: calc(var(--size) * 6 / 5);
    height: calc(var(--size) * 6 / 5);
  }
  13% {
    width: var(--size);
    height: var(--size);
    box-shadow: var(--point-shadow);
  }
  22% {
    transform: translate(var(--third-x), var(--third-y));
    background-color: var(--infobiotech);
  }
  33% {
    transform: translate(calc(var(--third-x) - var(--unit)), var(--third-y));
  }
  33% {
    transform: translate(calc(var(--third-x) - var(--unit)), var(--third-y));
  }
  48% {
    transform: translate(calc(var(--third-x) - var(--unit)), calc(var(--third-y) - var(--unit)));
  }
  48% {
    transform: translate(calc(var(--third-x) - var(--unit)), calc(var(--third-y) - var(--unit)));
  }
  63% {
    transform: translate(var(--third-x), calc(var(--third-y) - var(--unit)));
  }
  63% {
    transform: translate(var(--third-x), calc(var(--third-y) - var(--unit)));
  }
  80% {
    transform: translate(var(--third-x), var(--third-y));
    background-color: var(--infobiotech);
  }
  90% {
    transform: translate(var(--third-x), var(--third-y));
    width: var(--size);
    height: var(--size);
    box-shadow: var(--point-shadow);
  }
  95% {
    border-radius: 50%;
  }
}

@keyframes load-fourth {
  5% {
    border-radius: 50%;
  }
  10% {
    transform: translate(var(--fourth-x), var(--fourth-y));
    width: calc(var(--size) * 6 / 5);
    height: calc(var(--size) * 6 / 5);
  }
  13% {
    width: var(--size);
    height: var(--size);
    box-shadow: var(--point-shadow);
  }
  22% {
    transform: translate(var(--fourth-x), var(--fourth-y));
    background-color: var(--infobiotech);
  }
  33% {
    transform: translate(var(--fourth-x), calc(var(--fourth-y) - var(--unit)));
  }
  33% {
    transform: translate(var(--fourth-x), calc(var(--fourth-y) - var(--unit)));
  }
  48% {
    transform: translate(calc(var(--fourth-x) + var(--unit)), calc(var(--fourth-y) - var(--unit)));
  }
  48% {
    transform: translate(calc(var(--fourth-x) + var(--unit)), calc(var(--fourth-y) - var(--unit)));
  }
  63% {
    transform: translate(calc(var(--fourth-x) + var(--unit)), var(--fourth-y));
  }
  63% {
    transform: translate(calc(var(--fourth-x) + var(--unit)), var(--fourth-y));
  }
  80% {
    transform: translate(var(--fourth-x), var(--fourth-y));
    background-color: var(--infobiotech);
  }
  90% {
    transform: translate(var(--fourth-x), var(--fourth-y));
    width: var(--size);
    height: var(--size);
    box-shadow: var(--point-shadow);
  }
  95% {
    border-radius: 50%;
  }
}
