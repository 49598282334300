.homepage-buttons {
  display: flex;
  flex-direction: column;
}

.homepage-buttons button {
  width: 100%;
  margin-bottom: 8px;
}

.homepage-centered {
  padding: 30vh 30vw;
  height: 100%;
}
