/**
 *
 *
@import-normalize; /* bring in normalize.css styles */
@import url('https://fonts.googleapis.com/css?family=Share');
/** VARIABLES **/
:root {
  --infobiotech: rgb(145, 0, 75);
  --main-color: var(--infobiotech);
  --lightgray: rgb(242, 242, 242);
  --mediumgray: rgb(120, 120, 120);
  --hardgray: rgb(25, 25, 25);
  --hardgrayshadow: rgb(24, 24, 24, 0.9);
}

* {
  font-family: 'Share', sans-serif, Arial;
}
.my-masonry-grid {
  display: flex;
  width: auto;
}
.my-masonry-grid_column {
  background-clip: padding-box;
}

.my-masonry-grid_column:last-child:not(:first-child) > div {
  margin-right: 1vw;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin: 1vw 0 1vw 1vw;
}

.appContent {
  background-color: rgb(242 242 242);
}

/**
 *  BUTTONS INTENT
 */

#btn-intent-success {
  background-image: none;
  background-color: var(--main-color);
  color: var(--lightgray);
}

#btn-intent-success:hover {
  background-color: rgb(155, 10, 70);
  color: rgb(252, 252, 252);
}

#btn-intent-danger {
  background-image: none;
  background-color: rgb(180, 37, 37);
  color: var(--lightgray);
}

#btn-intent-danger:hover {
  background-color: rgb(165, 37, 37);
  color: rgb(252, 252, 252);
}

#btn-intent-active {
  background-color: rgb(216, 225, 232);
  color: rgb(24, 32, 38);
  cursor: default;
  background-image: none;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
}

.opacity-disabled {
  opacity: 0.75;
}

.mh-2 {
  margin: 0 2vw;
}
